import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
// import MySwiper2 from '../../../components/Swiper2'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// images
import b1 from '../../../assets/images/gates/bell/b1.jpg'
import b2 from '../../../assets/images/gates/bell/b2.jpg'
import b3 from '../../../assets/images/gates/bell/b3.jpg'
import b4 from '../../../assets/images/gates/bell/b4.jpg'
import b5 from '../../../assets/images/gates/bell/b5.jpg'
import b6 from '../../../assets/images/gates/bell/b6.jpg'
import b7 from '../../../assets/images/gates/bell/b7.jpg'
import b8 from '../../../assets/images/gates/bell/b8.jpg'
import b9 from '../../../assets/images/gates/bell/b9.jpg'
import b10 from '../../../assets/images/gates/bell/b10.jpg'
import b11 from '../../../assets/images/gates/bell/b11.jpg'
import b12 from '../../../assets/images/gates/bell/b12.jpg'

const gates = [
  {
    label: 'B1',
    img: b1,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B1.pdf',
  },
  {
    label: 'B2',
    img: b2,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B2.pdf',
  },
  {
    label: 'B3',
    img: b3,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B3.pdf',
  },
  {
    label: 'B4',
    img: b4,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B4.pdf',
  },
  {
    label: 'B5',
    img: b5,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B5.pdf',
  },
  {
    label: 'B6',
    img: b6,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B6.pdf',
  },

  {
    label: 'B7',
    img: b7,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B7.pdf',
  },
  {
    label: 'B8',
    img: b8,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B8.pdf',
  },
  {
    label: 'B9',
    img: b9,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B9.pdf',
  },
  {
    label: 'B10',
    img: b10,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B10.pdf',
  },
  {
    label: 'B11',
    img: b11,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B11.pdf',
  },
  {
    label: 'B12',
    img: b12,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B12.pdf',
  },
]

class bellGatesPage extends React.Component {
  render() {
    const siteTitle = 'Bell Gates - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'Bell Gates in Arlington, TX. Call  for Residential and Commercial Gate Services in Dallas-Fort Worth, TX - We Offer Free Estimates'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Bell Gate Designs</h1>
            <hr />
          </div>
        </div>
        <div className=" container d-padding-t d-padding-b text-center bell-gates">
          <div className="row">
            <div className="col-md-12 mb-5">
              <p className="mx-auto">
                J&J Gate Services is available to design and build the perfect
                gate system for your home or office. These bell gate designs are
                just a few of the fully customizable gate options we offer. We
                can meet with you to find the right gate that fits your
                specifications and works well with your property. For more
                information about bell gate design and installation in
                Dallas-Fort Worth, TX, give us a call at.{' '}
                <strong>
                  Click on any of the designs below for a pdf file you can print
                  or download.
                </strong>{' '}
              </p>
            </div>
            {gates.map(gate => (
              <div className="col-md-4">
                <div className="img-thumbnail shadow my-3 pt-3">
                  <a href={gate.pdf} target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage src={gate.img} alt="Bell Gate" />
                  </a>
                  <h5 class="mt-3">{gate.label}</h5>
                </div>
              </div>
            ))}
            {/* <MySwiper2 slidesperview={3}>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b1} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B1</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b2} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B2</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b3} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B3</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b4} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B4</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b5} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B5</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b6} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B6</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b7} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B7</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B8.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b8} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B8</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b9} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B9</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B10.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b10} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B10</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085285/jjgates/bell-gates-pdf/B11.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b11} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B11</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085284/jjgates/bell-gates-pdf/B12.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={b12} alt="Bell Gate" />
                </a>

                <h5 class="mt-0">B12</h5>
              </div>
            </MySwiper2> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default bellGatesPage

export const bellGatesPageQuery = graphql`
  query bellGatesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
